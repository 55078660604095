import React from 'react'
import './style.css'
import MyAdvert from './advertisement';
import ad from './addAdvirtisment'
import {Link, useNavigate} from 'react-router-dom'
import { appContext, homeContext} from '../App'
import {  useContext } from 'react'

function Side() {
  const navigate = useNavigate()
  const {token, setToken} = useContext(appContext)
  return (
    <div className='bg-white'>
        <div className='m-2'>
          <span className='brand-name fs-4'>BEMNET HAILU</span>
        </div>
        <hr className='text-dark '/>
        <div className='list-group list-group-flush'>
            <a className='list-group-item list-group-item-action my-2'>
                <span>Dashboard</span>
            </a>
            <a className='list-group-item list-group-item-action my-2'>
                <span>Home</span>
            </a>
            <a className='list-group-item list-group-item-action my-2'>
            <Link to='/add-advertisment'><span>Add Advertisement</span></Link>
            </a>
            <a className='list-group-item list-group-item-action my-2'>
            <Link to='/get-advertisments'><span>Advertisement</span></Link>
            </a>
            <a className='list-group-item list-group-item-action my-2'>
            <button onClick={() => {
                    localStorage.removeItem('shiromeda')
                    setToken(null)
                    navigate('/login')
                }}>LogOut</button>
            </a>
        </div>
    </div>
  )
}

export default Side