import User from './Admin/Users';
import Side from './Admin/Side';
import MyAdvert from './Admin/advertisement';
import ad from './Admin/addAdvirtisment'

function Home() {
  return (
    <div>
    <div className="d-flex home">
      <div className='d-flex sidebar flex-column flex-shrink-0 text-black bg-dark'>
        <div className='nav nav-pills flex-column mb-auto px-0'>
      <Side/>
        </div>
      </div>
    </div>
    <div className='content'>
      <User/>
      </div>
    </div>
  );
}

export default Home;
