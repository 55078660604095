import React, { useContext , useState} from 'react'
// import { Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {appContext} from '../App'
import './auth.css'
import { baseUrl } from '../Constant';
import { ArrowLeft } from 'react-feather';
import Logo from './Abulica.png'
import {Link, useNavigate} from 'react-router-dom'
//forgot password



 function SignIn() {
   
  const [error, setError] = useState(false)
  console.log(useContext(appContext))
    const {setToken} = useContext(appContext)
    //const navigate = useNavigate()
    async function onSubmit(event){
        event.preventDefault()
        let username = event.target.username.value
        let password = event.target.password.value
        let response = await axios({
            method: 'post',
            url: baseUrl + '/api/Login',
            data: {
                username, password, role: 'admin'
            }
        })
        console.log('hello')
        console.log(response.data)
        if(response.data.success){
            localStorage.setItem('AbulicaAdmin', response.data.token)
            setToken(response.data.token)
             //navigate('/')
            window.location.href = '/'
        } else {
            setError(true)
            
        }
    }
  return (
    <div className='bodyCard'>
      
    <div className='homeCard'>
        <form onSubmit={onSubmit}>
            {error &&<p>Incorrect name or password </p>}
            <div className='admin'>
            <img src={(Logo)} />
            <h1>Admin Yeha Bytes</h1>
        </div>
            <div className='SideCard'>
            <input className='input' type='text'  placeholder='Username' name='username' required/>
           </div>
            <div className='SideCard'>
            <input  className='input' type='password'  placeholder='Password' name='password' required/>
            </div>
            <div className='button'>
            <input type='submit' value={'Login'} className='sub' /> 
            
            </div>
        </form>
    </div>
   </div>  
  )
}
export default SignIn