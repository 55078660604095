import React, {useState, useEffect} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { createContext } from 'react';
import Body from './Body';
import Login from './Admin/Login'; 
import Error from './Error';
import Advertisement from './Admin/advertisement';
import AddAdvertisement from './Admin/addAdvirtisment';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import Home from './Home';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Body />,
    children: [
      {
        path: '/',
        element: <Home/>
      },
      {
        path: 'Login',
        element: <Login />
      },
      {
        path: '*',
        element: <Error />
      },
      {
        path: 'add-advertisment',
        element: <AddAdvertisement />
      },
      {
        path: 'get-advertisments',
        element: <Advertisement />
      }
    ]
  }
])
export const appContext = createContext()
 
function App() {
  const [token, setToken]= useState(null)
  

  useEffect(() => {
    let  _token = localStorage.getItem('AbulicaAdmin')
    if(_token){
      setToken(_token)
    }
  }, [])
  return (
    
      <appContext.Provider value={{token, setToken}}>
        {token ? (
        <RouterProvider router={router} />
        ) : <Login />}
      </appContext.Provider>
    

  );
}

export default App;
