import { useEffect, useState } from "react"
import React from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css';
// import { Link } from "react-router-dom"


function Home() {
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get('https://localhost:2080/api/users')
        .then(res => setData(res.data.userList))
        .catch(err => console.log(err))
    },[])

  return (
    <div className='d-flex flex-column justify-content-center align-items-center bg-light vh-100'>
      <h1>list of user</h1>
      <div className='w-75 rounded bg-white border shadow p-4'>
        <p>Total Users: {data.length}</p>
        {/* <div className="d-flex justify-content-end"><Link  to='/Create' className="btn btn-success">Add+</Link></div> */}
        <table className="table table-striped">
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Action</th>
        </table>
        <tbody>
            {
                data.map((d, i) => (
                    <tr key={i} className="table table-striped">
                        <td>{d.id}</td>
                        <td>{d.name}</td>
                        <td>{d.email}</td>
                        <td>{d.Phone}</td>
                    </tr>
                ))
            }
        </tbody>
      </div>
    </div>
  )
}

export default Home