import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Constant";
import axios from "axios";
import { appContext } from "../App";
// import Center from "../Center";

export default function Adpost() {
    const [error, setError] = useState(false)
    const token = localStorage.getItem('AbulicaAdmin')
    const navigate = useNavigate()
    async function onSubmit(e){
        e.preventDefault()
        let image = e.target.image.files[0]
        let formdata = new FormData()
        formdata.append('image', image)
        let response = await fetch(baseUrl + '/api/addAdvert', {
            body: formdata,
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        response = await response.json()
        if(response.success){
            navigate('/get-advertisments')
        } else {
            setError(true)
        }
    }
    return (
        <div>
            {/* <Center/> */}
        <form onSubmit={onSubmit} className="homeCard" style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 30,
            borderRadius: 10,
            backgroundColor: '#8ee443',
            gap: 15,
            alignItems: 'center'
        }}>
            <h3>Add Advertisement</h3>
            <input type='file' id="file" name='image'/>
            <label for='file'>Choose Photo</label>
            <input type='submit' className='btn' value={'AdAdvertisement'}/>
        </form>
        </div>
      )
    }
