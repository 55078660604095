import React, {useContext, useEffect, useState} from "react";
import { baseUrl } from "../Constant";
import axios from "axios";
import { appContext } from "../App";
import { Link, useNavigate } from "react-router-dom";
import {Edit, Trash2, ArrowLeft} from 'react-feather'

export default function User() {
    const history = useNavigate()
    const [advertiements, setAdvertiements] = useState([])
    const token = localStorage.getItem('AbulicaAdmin')
    console.log(token)
    useEffect(() => {
        axios({
            method: 'get',
            url: baseUrl + '/api/myAdvert',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            console.log(response.data)
            if(response.data.success){
                setAdvertiements(response.data.advertiements)
            }
        })
    },[])

  const deleteProduct = async (id) => { 
   await fetch(baseUrl+`/api/deleteadvertiement/${id}`, {
        method: 'get', 
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(() => {
        alert("Post deleted!");
        setAdvertiements(advertiements.filter(ad => ad.id != id))
      });
   
  }   
    return (
        //logout fun and profile
        <div >
            <Link onClick={()=>history(-1)} ><ArrowLeft /></Link>
            
            <div className="all" style={{ gap: 20}}>
            {advertiements.length == 0 && <p>You have no advertisements yet!</p>}
           {advertiements.map(product => (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap :10,
                border: '1px solid black',
                borderRadius: 5,
                padding: 10,
                alignSelf: 'start',
               
            }}  key={ product.id}>
                <img src={baseUrl + product.image} alt='' style={{
                    width: 250,
                    height: 200,
                    objectFit: 'cover'
                }} />
                <div> 
                    <button onClick={() => deleteProduct( product.id)}><Trash2 color="red"/></button>
                </div>
            </div>
           ))} 
        </div>
        </div>
      )
}